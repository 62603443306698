import { API_HOST } from './config'
export default {
  // ADMIN ROUTES
  ADMIN_USERS: `${API_HOST}admin/users/users`,
  SEND_DUMMY_EMAIL: `${API_HOST}admin/push_setting/send_custom_notifications`,
  ADMIN_USER_DETAIL: `${API_HOST}admin/users/user_detail`,
  ADMIN_DELETE_USER: `${API_HOST}admin/users/delete_user`,
  ADMIN_USERS_EDIT: `${API_HOST}admin/users/user_edit`,
  GET_ACCOUNT_TABS: `${API_HOST}front/account/get_tabs`,

  ADMIN_USERS_ALL_CLINICS: `${API_HOST}admin/users/all_clinics`,
  ADMIN_USERS_ADD_CLINIC: `${API_HOST}admin/users/create_clinic`,
  ADMIN_USERS_ADD_EMPLOYEE: `${API_HOST}admin/users/create_employee`,
  ADMIN_USERS_UPDATE_CLINIC: `${API_HOST}admin/users/update_clinic`,
  ADMIN_USERS_DELETE_CLINIC: `${API_HOST}admin/users/delete_clinic`,
  ADMIN_USERS_ADD_FRONT_USER: `${API_HOST}admin/users/create_front_user`,
  ADMIN_USERS_ADD_PATIENT_USER: `${API_HOST}admin/users/create_patient_user`,
  ADMIN_USERS_CHANGE_TO_PATIENT: `${API_HOST}admin/users/change_to_patient`,
  ADMIN_USERS_CHANGE_TO_PERSONAL_USER: `${API_HOST}admin/users/change_to_personal_user`,
  ADMIN_USERS_ADD_BLOG_POST: `${API_HOST}admin/blog/add_post`,
  ADMIN_USERS_GET_ALL_BLOG_POST: `${API_HOST}admin/blog/get_all_post`,
  ADMIN_USERS_DELETE_BLOG_POST: `${API_HOST}admin/blog/delete_post`,
  ADMIN_USERS_UPDATE_BLOG_POST: `${API_HOST}admin/blog/update_post`,
  ADMIN_USERS_ALL_COUNTS: `${API_HOST}admin/users/count_all_users`,
  PERSONAL_USER_PAYMENT_STATS: `${API_HOST}admin/payments/get_personal_invoice`,

  //CLINIC API
  CLINIC_USERS_ACTIVE: `${API_HOST}admin/users/create_clinic_user`,
  CREATE_CLINIC_USER: `${API_HOST}admin/users/create_clinic_user`,
  CREATE_ADMIN_USER: `${API_HOST}admin/users/create_admin_user`,
  CLINIC_ALL_PATIENTS: `${API_HOST}admin/users/get_clinic_patients`,
  CLINIC_ALL_EMPLOYEES: `${API_HOST}admin/users/get_clinic_employees`,
  APPROVED_BY_USER_PATIENTS: `${API_HOST}admin/users/confirmed_by_user`,
  APPROVED_BY_ADMIN_PATIENTS: `${API_HOST}admin/users/confirmed_by_admin`,

  // COMMON API
  USER_DETAIL: `${API_HOST}front/account/user_detail`,
  USER_STATS: `${API_HOST}clinics/patients/patient_stats`,
   BUSSINESS_pACKAGE: `${API_HOST}admin/users/get_busniess_packages`
 
}
