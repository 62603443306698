// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { users } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

import { displayErrorToast, displaySuccessToast } from '../../../../utility/helper'
export const getAdminUsers = createAsyncThunk('adminUsers/getAdminUsers', async (roleid) => {
  const response = await axiosRequest.post(users.ADMIN_USERS, { roleid })
  console.log('--------response userdata-------', response?.data)
  return { data: response?.data?.data }
})

export const getAdminUsersList = createAsyncThunk('adminUsers/getAdminUsersList', async (roleid) => {
  const response = await axiosRequest.post(users.ADMIN_USERS, { roleid })
  console.log('--------response getAdminUsersList-------', response?.data)
  return { data: response?.data?.data }
})

export const sendModifyEmail = createAsyncThunk('adminUsers/sendModifyEmail', async (data) => {
  const response = await axiosRequest.post(users.SEND_DUMMY_EMAIL, data)
  console.log('--------response getAdminUsersList-------', response?.data)
  return { data: response?.data?.data }
})

export const setAdminUsers = createAsyncThunk('adminUsers/setAdminUsers', async (data) => {
  return { data }
})

export const getUserDetail = createAsyncThunk('adminUsers/getUserDetail', async (userid) => {
  const response = await axiosRequest.post(users.ADMIN_USER_DETAIL, { userid })
  console.log('user detail response', response.data)
  return { data: response?.data?.data }
})

export const deleteAdminUser = createAsyncThunk('adminUsers/deleteAdminUser', async (userid) => {
  const response = await axiosRequest.post(users.ADMIN_DELETE_USER, { userid })
  return { id: userid, status: response.data?.status }
})

//ACTIVE CLINIC USERS
export const activeClinicUser = createAsyncThunk('adminUsers/activeClinicUser', async (data, { dispatch }) => {
  const response = await axiosRequest.post(users.CLINIC_USERS_ACTIVE, data)
  console.log('----------respnose All Clinics -------', response?.data)
  if (response?.data?.status) {
    dispatch(getAllClinics())
  }
  return { status: response.data?.status, message: response?.data?.message }
})
// GET CLINICS
export const getAllClinics = createAsyncThunk('adminUsers/getAllClinics', async () => {
  const response = await axiosRequest.get(users.ADMIN_USERS_ALL_CLINICS)
  return { data: response?.data?.data ?? [] }
})
export const getUrl = createAsyncThunk('adminUsers/getUrl', async () => {
  const response = await axiosRequest.get(users.GET_ACCOUNT_TABS)
  return { data: response?.data?.data ?? [] }
})

export const setAllClinics = createAsyncThunk('adminUsers/setAllClinics', async (data) => {
  return { data }
})

export const addClinic = createAsyncThunk('adminUsers/addClinic', async (data) => {
  const response = await axiosRequest.post(users.ADMIN_USERS_ADD_CLINIC, data)
  return { status: response?.data?.status ?? false, message: response?.data?.message ?? 'Something is wrong' }
})

export const addNewEmployee = createAsyncThunk('addNewEmployee/addClinic', async (data) => {
  const response = await axiosRequest.post(users.ADMIN_USERS_ADD_EMPLOYEE, data)
  return { status: response?.data?.status ?? false, message: response?.data?.message ?? 'Something is wrong' }
})

export const editClinicData = createAsyncThunk('adminUsers/editClinicData', async (data) => {
  const response = await axiosRequest.post(users.ADMIN_USERS_UPDATE_CLINIC, data)
  return { status: response?.data?.status ?? false, message: response?.data?.message ?? 'Something is wrong' }
})

export const deleteClinic = createAsyncThunk('adminUsers/deleteClinic', async (id) => {
  const response = await axiosRequest.post(users.ADMIN_USERS_DELETE_CLINIC, { id })
  return { id, status: response?.data?.status }
})

export const addFrontUser = createAsyncThunk('adminUsers/addFrontUser', async (data) => {
  const response = await axiosRequest.post(users.ADMIN_USERS_ADD_FRONT_USER, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const addPatientUser = createAsyncThunk('adminUsers/addPatientUser', async (data) => {
  const response = await axiosRequest.post(users.ADMIN_USERS_ADD_PATIENT_USER, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const addClinicUser = createAsyncThunk('adminUsers/addClinicUser', async (data) => {
  const response = await axiosRequest.post(users.CREATE_CLINIC_USER, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const addAdminUser = createAsyncThunk('adminUsers/addAdminUser', async (data) => {
  const response = await axiosRequest.post(users.CREATE_ADMIN_USER, data)
  console.log('===add admin response===', response.data)
  return { status: response?.data?.status, message: response?.data?.message }
})

// Update User Profile

export const updateUsersProfile = createAsyncThunk('adminUsers/updateUsersProfile', async (data) => {
  const response = await axiosRequest.post(users.ADMIN_USERS_EDIT, data)
  return { status: response?.data?.status }
})

// Change to Patient

export const changeToPatient = createAsyncThunk('adminUsers/changeToPatient', async (data, { dispatch }) => {
  const response = await axiosRequest.post(users.ADMIN_USERS_CHANGE_TO_PATIENT, data)
  if (response?.data?.status) {
    dispatch(getAdminUsers(0))
  }
  return { status: response?.data?.status }
})
// Chnage to Personal User
export const changeToPesonalUser = createAsyncThunk('adminUsers/changeToPesonalUser', async (userid, { dispatch }) => {
  const response = await axiosRequest.post(users.ADMIN_USERS_CHANGE_TO_PERSONAL_USER, userid)
  if (response?.data?.status) {
    dispatch(getAdminUsers(0))
  }
  return { status: response?.data?.status }
})

export const getUserStats = createAsyncThunk('adminUsers/getUserStats', async (userid) => {
  const response = await axiosRequest.post(users.USER_STATS, { userid })
  return { status: response?.data?.status, data: response?.data?.data ?? {} }
})

export const packageUpdate = createAsyncThunk('adminUsers/packageUpdate', async () => {
  const response = await axiosRequest.post(users.BUSSINESS_pACKAGE)
  return { status: response?.data?.status, data: response?.data ?? {} }
})

export const setUserStats = createAsyncThunk('adminUsers/setUserStats', async (data) => {
  return { data }
})

// Get All Patients Details
export const getClinicAllPatients = createAsyncThunk('adminUsers/getClinicAllPatients', async (data) => {
  console.log('--------getClinicAllPatients----data-', data)
  const response = await axiosRequest.post(users.CLINIC_ALL_PATIENTS, data)
  console.log('----getClinicAllPatients----respnose----', response)
  return { data: response?.data?.data ?? [] }
})
//Add Blogs Post
export const addblogpost = createAsyncThunk('adminUsers/addblogpost', async (data) => {
  const response = await axiosRequest?.post(users.ADMIN_USERS_ADD_BLOG_POST, data)
  console.log('===add blog response===', response.data)
  return { status: response?.data?.status, message: response?.data?.message }
})
//GET ALL BLOG POSTS
export const getallblogpost = createAsyncThunk('adminUsers/getallblogpost', async (data) => {
  const response = await axiosRequest?.post(users.ADMIN_USERS_GET_ALL_BLOG_POST, data)
  console.log('===get all blog response===', response?.data)
  return { data: response?.data?.data ?? [], message: response?.data?.message }
})
//DELETE BLOG POST
export const deleteblogpost = createAsyncThunk('adminUsers/deleteblogpost', async (data) => {
  const response = await axiosRequest?.post(users.ADMIN_USERS_DELETE_BLOG_POST, data)
  console.log('===delete blog post response===', response?.data)
  return { data: response?.data?.data ?? [], message: response?.data?.message }
})
//UPDATE BLOG POST
export const updateblogpost = createAsyncThunk('adminUsers/updateblogpost', async (data) => {
  const response = await axiosRequest?.post(users.ADMIN_USERS_UPDATE_BLOG_POST, data)
  console.log('===update blog post response===', response?.data)
  return { data: response?.data?.data ?? [], message: response?.data?.message }
})
//
export const setClinicAllPatients = createAsyncThunk('clinicPatients/setClinicPatients', async (data) => {
  return { data }
})
export const setClinic = createAsyncThunk('clinicPatients/setClinic', async (data) => {
  return { data }
})
// Approve By User Patients

export const approveByUserPatient = createAsyncThunk('clinicPatients/approveByUserPatient', async (data) => {
  const response = await axiosRequest.post(users.APPROVED_BY_USER_PATIENTS, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

// Approve By Admin Patients

export const approveByAdminPatient = createAsyncThunk('clinicPatients/approveByAdminPatient', async (data) => {
  const response = await axiosRequest.post(users.APPROVED_BY_ADMIN_PATIENTS, data)
  return { status: response?.data?.status, message: response?.data?.message }
})
// Get All Employees Details
export const getClinicAllEmployees = createAsyncThunk('adminUsers/getClinicAllEmployees', async (data) => {
  console.log('--------getClinicAllEmployees--data---', data)
  const response = await axiosRequest.post(users.CLINIC_ALL_EMPLOYEES, data)
  console.log('----getClinicAllEmployees----respnose----', response)
  return { data: response?.data?.data ?? [] }
})
// ALL USERS COUNTS
export const getAllUserCounts = createAsyncThunk('adminUsers/getAllUserCounts', async () => {
  const response = await axiosRequest.post(users.ADMIN_USERS_ALL_COUNTS)
  return { data: response?.data?.data ?? {} }
})

export const personalpaymentstats = createAsyncThunk('adminUsers/personalpaymentstats ', async (data) => {
  const response = await axiosRequest.post(users.PERSONAL_USER_PAYMENT_STATS, data)
  console.log("response: ", response)
  
  return { data: response?.data ?? {} }
})
export const adminUsersSlice = createSlice({
  name: 'adminUsers',
  initialState: {
    data: [],
    UserCount: {},
    loading: true,
    userDetail: {},
    allClinics: [],
    clinicAllPatients: [],
    clinicAllEmployees: [],
    adminUsersList: [],
    blogLoading: true,
    sendTestEmail: [],
    getTabsData: [],
    packageUpdateData: [],
    packageUpdateDataLoading: false,
    clinicsLoading: true,
    employeeLoading: true,
    patientLoading: true,
    userStats: {},
    userStatsLoading: false,
    paymentStats: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminUsers.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setAdminUsers.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAdminUsers.pending, (state) => {
        console.log('LOADING...')
        state.loading = true
      })
      .addCase(getAdminUsers.rejected, (state) => {
        console.log('REJECTED...')
        state.loading = false
      })
      .addCase(getAdminUsersList.fulfilled, (state, { payload }) => {
        console.log('----fullfilled-- getAdminUserList----', payload?.data)
        state.adminUsersList = payload?.data ?? []
      })
      //
      .addCase(sendModifyEmail.fulfilled, (state, { payload }) => {
        console.log('----fullfilled-- getAdminUserList----', payload?.data)
        state.sendModifyEmailData = payload?.data ?? []
      })

      .addCase(deleteAdminUser.fulfilled, (state, { payload }) => {
        console.log(`DELETED USER ID ${payload?.id}`)
      })
      .addCase(getUserDetail.fulfilled, (state, { payload }) => {
        state.userDetail = payload?.data ?? []
      })
      .addCase(getAllClinics.fulfilled, (state, { payload }) => {
        state.allClinics = payload?.data ?? []
        state.clinicsLoading = false
      })
      .addCase(getAllClinics.pending, (state) => {
        console.log('LOADING...')
        state.clinicsLoading = true
      })
      .addCase(getAllClinics.rejected, (state) => {
        console.log('REJECTED...')
        state.clinicsLoading = false
      })
      //

      .addCase(getUrl.fulfilled, (state, { payload }) => {
        state.getTabsData = payload?.data ?? []
        state.tabsLoading = false
      })
      .addCase(getUrl.pending, (state) => {
        console.log('LOADING...')
        state.tabsLoading = true
      })
      .addCase(getUrl.rejected, (state) => {
        console.log('REJECTED...')
        state.tabsLoading = false
      })

      //
      .addCase(setAllClinics.fulfilled, (state, { payload }) => {
        state.allClinics = payload?.data ?? []
        state.clinicsLoading = false
      })

      .addCase(addClinic.fulfilled, (state, { payload }) => {
        console.log(`ADDED DATA ${JSON.stringify(payload?.status)}`)
      })

      .addCase(addNewEmployee.fulfilled, (state, { payload }) => {
        console.log(`ADDED DATA ${JSON.stringify(payload?.status)}`)
      })

      .addCase(editClinicData.fulfilled, (state, { payload }) => {
        console.log(`DATA UPDATED ${JSON.stringify(payload?.status)}`)
      })
      .addCase(deleteClinic.fulfilled, (state, { payload }) => {
        console.log(`DELETED CLINIC ID ${payload?.id}`)
      })
      .addCase(addFrontUser.fulfilled, (state, { payload }) => {
        displaySuccessToast('Front User has been Added Successfully')
        console.log(`ADDED FRONT USER ${payload.status}`)
      })
      .addCase(addPatientUser.fulfilled, (state, { payload }) => {
        displaySuccessToast('Patient has been Added Successfully')
        console.log(`ADDED PATIENT ${payload.status}`)
      })
      //BlogPOST
      .addCase(addblogpost.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(addblogpost.fulfilled, (state, { payload }) => {
        displaySuccessToast('Blog has been Added Successfully')
        state.status = 'succeeded'
        console.log(`ADDED BLOG ${payload.status}`)
      })
      .addCase(addblogpost.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message // Assuming the error message is available in action.error.message
        state.errorMessage = action.payload?.message || 'An error occurred' // Store the specific error message from the server
      })
      .addCase(getallblogpost.pending, (state) => {
        state.status = 'pending'
        state.blogLoading = true
      })
      .addCase(getallblogpost.fulfilled, (state, { payload }) => {
        state.status = 'fulfilled'
        state.blogLoading = false
        state.blogPosts = payload?.data ?? [] // Assuming the payload contains the blog posts
      })
      .addCase(getallblogpost.rejected, (state, action) => {
        state.status = 'rejected'
        state.blogLoading = false
        state.error = action.error.message // Assuming the payload contains an error message
      })
      .addCase(deleteblogpost.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteblogpost.fulfilled, (state, { payload }) => {
        displaySuccessToast('Blog has been Deleted Successfully')
        // state.status = 'succeeded'
        // Assuming your payload contains the deleted blog post's ID
        const deletedblogId = payload.id

        // Filter out the deleted blog post from the blogPosts array
        state.blogPosts = state.blogPosts.filter((blog) => blog.id !== deletedblogId)
      })

      .addCase(deleteblogpost.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(updateblogpost.pending, (state) => {
        state.error = null
      })
      .addCase(updateblogpost.fulfilled, (state, { payload }) => {
        displaySuccessToast('Blog has been Updated Successfully')
        state.blogPost = payload.data // assuming your data is in payload.data
      })

      .addCase(updateblogpost.rejected, (state, action) => {
        state.error = action.error.message // assuming you want to store the error message
      })
      // Update User Profile
      .addCase(updateUsersProfile.fulfilled, (state, { payload }) => {
        displaySuccessToast('User has been Updated Successfully')
        console.log(`Updated User ${payload.status}`)
      })
      // Change To Patient
      .addCase(changeToPatient.fulfilled, (state, { payload }) => {
        displaySuccessToast('User Status has been Changed Successfully')
        console.log(`Updated User ${payload.status}`)
      })
      // Change To Personal User
      .addCase(changeToPesonalUser.fulfilled, (state, { payload }) => {
        displaySuccessToast('User Status has been Changed Successfully')
        console.log(`Updated User ${payload.status}`)
      })
      .addCase(activeClinicUser.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Clinic User has been Added Successfully')
        } else {
          displayErrorToast(payload?.message)
        }
      })
      .addCase(getUserStats.fulfilled, (state, { payload }) => {
        state.userStats = payload?.data
        state.userStatsLoading = false
      })
      .addCase(getUserStats.pending, (state) => {
        state.userStatsLoading = true
      })
      .addCase(getUserStats.rejected, (state) => {
        state.userStatsLoading = false
      })
      //
      .addCase(packageUpdate.fulfilled, (state, { payload }) => {
        state.packageUpdateData = payload?.data
        state.packageUpdateDataLoading = false
      })
      .addCase(packageUpdate.pending, (state) => {
        state.packageUpdateDataLoading = true
      })
      .addCase(packageUpdate.rejected, (state) => {
        state.packageUpdateDataLoading = false
      })
      //
      .addCase(setUserStats.fulfilled, (state, { payload }) => {
        state.userStats = payload?.data
      })
      .addCase(getClinicAllPatients.fulfilled, (state, { payload }) => {
        state.data = payload?.data
        state.loading = false
      })
      .addCase(getClinicAllPatients.pending, (state) => {
        state.loading = true
      })
      .addCase(getClinicAllPatients.rejected, (state) => {
        state.loading = false
      })
      //
      .addCase(setClinicAllPatients.fulfilled, (state, { payload }) => {
        state.data = payload?.data
        state.loading = false
      })
      //
      .addCase(setClinic.fulfilled, (state, { payload }) => {
        state.data = payload?.data
        state.loading = false
      })
      // Approve by User Patient
      .addCase(approveByUserPatient.fulfilled, (state, { payload }) => {
        console.log('====Update Patient Setting======', payload.status)
        displaySuccessToast(payload.message)
      })

      // Approve by User Patient
      .addCase(approveByAdminPatient.fulfilled, (state, { payload }) => {
        console.log('====Update Patient Setting======', payload.status)
        displaySuccessToast(payload.message)
      })
      //
      .addCase(getClinicAllEmployees.fulfilled, (state, { payload }) => {
        state.data = payload?.data
        state.loading = false
      })
      .addCase(getClinicAllEmployees.pending, (state) => {
        state.loading = true
      })
      .addCase(getClinicAllEmployees.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAllUserCounts.pending, () => {
        console.log('User count pending')
      })
      .addCase(getAllUserCounts.fulfilled, (state, action) => {
        state.UserCount = action.payload
        console.log('User count fulfilled')
      })
      .addCase(getAllUserCounts.rejected, () => {
        console.log('User count rejected')
      })

       .addCase(personalpaymentstats.pending, (state) => {
              state.loading = true
              state.error = null
            })
            .addCase(personalpaymentstats.fulfilled, (state, action) => {
              state.loading = false
              state.paymentStats = action.payload.data
            })
            .addCase(personalpaymentstats.rejected, (state, action) => {
              state.loading = false
              state.error = action.error.message
            })
  }
})

export default adminUsersSlice.reducer
